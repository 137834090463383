import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Footer from "../components/Footer";
import "./react-bootstrap-table.min.css";
import "./WhatWeBelieve.css";

export default class WhatWeBelieve extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whatWeBelieve: [
        {
          id: 1,
          belief: "We believe in celebrating everything!"
        },
        {
          id: 2,
          belief: "We believe in Sweet Treats and Messy Smiles."
        },
        {
          id: 3,
          belief: "We believe everyone should feel special."
        },
        {
          id: 4,
          belief: "We believe in being nice to each other."
        },
        {
          id: 5,
          belief: "We believe in good manners."
        },
        {
          id: 6,
          belief: "We believe everyone has an inner child."
        },
        {
          id: 7,
          belief: "We believe we should laugh and play more."
        },
        {
          id: 8,
          belief: "We believe in trying something first."
        },
        {
          id: 9,
          belief: "We believe in learning and laughing from our mistakes."
        },
        {
          id: 10,
          belief:
            "We believe more time should be spent with family and friends."
        }
      ]
    };
  }

  render() {
    return (
      <div className="WhatWeBelieve">
        <div className="lander">
          <div className="announcement">
            <div className="image">
              <img
                src="./images/logos/Main_Street_Treats_Inc_logo_Art_TM.png"
                width="70%"
                alt="Main Street Treats, Inc.TM Logo"
              />
            </div>
            <div className="beliefs">
              <BootstrapTable data={this.state.whatWeBelieve} version="4">
                <TableHeaderColumn dataField="belief" isKey>
                  What We Believe...
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
          <p>&nbsp;</p>
          <Footer />
        </div>
      </div>
    );
  }
}
