import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./containers/Home";
import SweeTreats from "./containers/SweeTreats";
import WhatWeBelieve from "./containers/WhatWeBelieve";
import AboutUs from "./containers/AboutUs";
import NotFound from "./containers/NotFound";

export default ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute
      path="/sweetreats"
      exact
      component={SweeTreats}
      props={childProps}
    />
    <AppliedRoute
      path="/whatwebelieve"
      exact
      component={WhatWeBelieve}
      props={childProps}
    />
    <AppliedRoute
      path="/aboutus"
      exact
      component={AboutUs}
      props={childProps}
    />
    <Route component={NotFound} />
  </Switch>
);
