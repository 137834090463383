import React, { Component } from "react";
import Footer from "../components/Footer";
import "./AboutUs.css";

export default class AboutUs extends Component {
  render() {
    return (
      <div className="AboutUs">
        <div className="lander">
          <div className="announcement">
            <div className="artist">
              <img
                src="./images/store/starving_artist.png"
                width="70%"
                alt="Main Street Treats, Inc.TM 328 West Main Street Sign"
              />
            </div>
            <div className="image_Bakery_Sign">
              <img
                src="./images/store/328_West_Main_Street_Sign.png"
                width="70%"
                alt="Main Street Treats, Inc.TM 328 West Main Street Sign"
              />
            </div>
            <div className="allabout">
	          <h3>To Our Dear Customers</h3> 
	                  <h4>An announcement from your friends at Main Street Treats! Read below!</h4>
                    <h5>It’s hard to believe that we have been creating cookies, cakes, 
                      and other specialty treats for the last 5 years!  At last count, over 
                      60,000 cookies have been designed in our kitchen.  60,000!! </h5>
                    <h5>We want to thank all of our followers and customers for 
                      the support, love, and kindness you have shown us over the 
                      years! There’s no greater honor than being trusted with the 
                      special treats for your most important celebrations! </h5>
                    <h5>As of July 15, we will no longer be taking custom orders. 
                      We will be taking steps to close our commercial kitchen on 
                      Main Street in the next couple of months and anticipate 
                      completing this work by September.</h5>
                    <h5>While it’s hard to step away from the powdered sugar and 
                      icing bags, the celebrations don’t stop here! Follow along 
                      with us in the next few months as we post about new surprises 
                      on the horizon!</h5>
                    <h5>We are so grateful for you all and hope to help you 
                      celebrate everything in new, exciting ways!</h5>
	            <h4>If you have any questions at all please call us at (317) 740-0204 or by email{" "}
	               <a href="mailto: info@mainstreetreats.com">
	                 info@mainstreetreats.com 
	               </a>
	            </h4>
            </div>
          </div>
          <p>&nbsp;</p>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
