export const photos = [
  {
    src: "./images/treats/teacher_basket.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/teacher_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/stpats_treats.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/st_patricks_day_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/Valentines_Sugar_Cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/dino-mite.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/chocolate_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/christmas_baby_jumper_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/christmas_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/holidays_in_carmel.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/snowman_basket.png",
    width: 1,
    height: 1
  },
  {
      src: "./images/treats/christmas_assortment.png",
      width: 1,
      height: 1
  },
  {
    src: "./images/treats/santa.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/carmel_santa.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/christmas_trees.png",
    width: 1,
    height: 1
  },
  {
      src: "./images/treats/thanks_pumpkin.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/pumpkin_top.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/pumpkin_bday_cake.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/pumpkins_leaves.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/pumpkin_cake.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/pumpkin_bouquet.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/turkey_cookies.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/baby_announce.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/bbq_bday.png",
      width: 1,
      height: 1
  },
  {
      src: "./images/treats/bday_basket.png",
      width: 1,
      height: 1
  },
  {
    src: "./images/treats/beach_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/bee_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/big_game_treats.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/cake_slice.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/car_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/carmel_artomobilia.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/carmel_arts_festival.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/carmel_cares.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/carmel_cares_2.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/CFD_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/childrens_museum_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/class_of_2020.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/covid_bday.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/cow_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/cowboy_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/cupcake_mountain.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/derby_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/dog_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/donut_dog.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/emoji_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/engagement.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/engagement_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/flower_girl.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/food_tray.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/guitar_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/guitar_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/half_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/home_sweet_home.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/ice_cream.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/mr_and_mrs_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/one_cookie_baskets.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/pancake_pig.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/pig_cake.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/school_rules.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/shark_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/shark_fins.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/smores.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/strawberry_one.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/taco_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/tenderloin.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/thanks.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/two_cookies.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/wild_about_you_basket.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/yummy_tummy.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/gender_reveal.png",
    width: 1,
    height: 1
  },
  {
    src: "./images/treats/narwahl.png",
    width: 1,
    height: 1
  },
  {
      src: "./images/treats/carmel_roundabouts.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/black_gold.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/brainard.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/6_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/coffee.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/11_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/communion_platter.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/two_year_birthday.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/jack_ryan.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/50_year_birthday.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/travel_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/new_year_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/basket_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/cinco_de_mayo.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/28_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/dino_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/unicorn_cupcakes.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/18_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/racing_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/confetti_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/baby.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/flower_drip_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/nurse_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/1_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/home_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/2_cake.png",
      width: 1,
      height: 1
    },    
    {
      src: "./images/treats/wedding_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/llama_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/sports_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/fox_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/paint_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/robbie_bday.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/cake_cupcake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/octopus_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/easter_basket.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/milk_and_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/strawberry_chocolate_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/mardi_gras_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/mini_unicorn_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/football_tier_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/football_chocolate_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/popcorn.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/whoopie_pies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/holiday_cupcakes.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/treatpack.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/black_and_white_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/pumpkin_sugar_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/cupcakes.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/chocolatechip_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/smores_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/art_themed_cake.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/art_themed_sugar_cookies.png",
      width: 1,
      height: 1
    },
    {
      src: "./images/treats/sprinkle_cupcakes.png",
      width: 1,
      height: 1
    }
  ];