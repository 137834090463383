import React from "react";
import "./Footer.css";

export default props => (
    <div className="fblock">
    <div className="social">
    <div className="facebook">
    <a
href="http://www.facebook.com/mainstreetreats"
target="_blank"
rel="noopener noreferrer"
    >
    <img
src="./images/footer/facebook.png"
height="60"
alt="Main Street Treats, Inc.TM Facebook"
    />
    </a>
    </div>
    <div className="instagram">
    <a
href="http://www.instagram.com/mainstreetreats"
target="_blank"
rel="noopener noreferrer"
    >
    <img
src="./images/footer/instagram.png"
height="45"
alt="Main Street Treats, Inc.TM Instagram"
    />
    </a>
    </div>
    <div className="twitter">
    <a
href="http://www.twitter.com/mainstreetreats"
target="_blank"
rel="noopener noreferrer"
    >
    <img
src="./images/footer/twitter.png"
height="60"
alt="Main Street Treats, Inc.TM Twitter"
    />
    </a>
    </div>
    <div className="pinterest">
    <a
href="http://www.pinterest.com/mainstreetreats"
target="_blank"
rel="noopener noreferrer"
    >
    <img
src="./images/footer/pinterest.png"
height="45"
alt="Main Street Treats, Inc.TM Pinterest"
    />
    </a>
    </div>
    </div>
    <div className="copyright">
    Copyright. &copy; 2017-2023. Main Street Treats, Inc.&trade; All Rights
Reserved.
</div>
</div>
);
