import React, { Component, useState, useCallback } from "react";
import { Jumbotron } from "react-bootstrap";
import { photos } from "./photos";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Footer from "../components/Footer";
import "./SweeTreats.css";

function Showcase() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default class SweeTreats extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="SweeTreats">
        <div className="lander">
          <Jumbotron />

          <Showcase />
          <p>&nbsp;</p>
          <Footer />
        </div>
      </div>
    );
  }
}
