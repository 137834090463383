import React from "react";
import Footer from "../components/Footer";
import "./NotFound.css";

export default () => (
  <div className="NotFound">
    <h3>Sorry, page not found!</h3>
    <p>&nbsp;</p>
    <Footer />
  </div>
);
