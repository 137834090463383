import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
/* import { Nav, Navbar } from "react-bootstrap"; */
import Routes from "./Routes";
import RouteNavItem from "./components/RouteNavItem";
import "./App.css";
import "./bootstrap.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div className="App container">
        <Navbar dark toggleable="sm">
          <NavbarBrand>
            <Link to="/">Main Street Treats, Inc.&trade;</Link>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <RouteNavItem key={1} href="/sweetreats">
                Sweet Treats
              </RouteNavItem>
              <RouteNavItem key={2} href="/whatwebelieve">
                What We Believe
              </RouteNavItem>
              <RouteNavItem key={4} href="/aboutus">
                About Us
              </RouteNavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <Routes />
      </div>
    );
  }
}

export default App;
